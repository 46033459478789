import { axiosInstance } from '../../network';

//Get Notifications
export const getNotificationsRequest = async (payload) => {
    return await axiosInstance.get(`api/notifications`, { params: payload });
};
//Seen Notifications
export const seenNotificationsRequest = async (payload) => {
    return await axiosInstance.patch(`api/notifications/seen`, payload);
};
