import { axiosInstance } from '../../network';

//Get Chat Rooms
export const getChatRooms = async (payload) => {
    return await axiosInstance.get(`api/messenger/rooms`, { params: payload });
};

//Get Chat Room
export const getChatRoom = async (payload) => {
    return await axiosInstance.get(`api/messenger/rooms/${payload}`);
};

//create message
export const createMessage = async (payload) => {
    return await axiosInstance.post(`api/messenger/message`, payload);
};
