import dashboard from './dashboard';
//import pages from './pages';
//import utilities from './utilities';
//import other from './other';
import system from './system';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, system]
};

export default menuItems;
