import { all } from 'redux-saga/effects';
import { authSagas } from './auth/sagas';
import { usersSagas } from './users/sagas';
import { notificationsSagas } from './notifications/sagas';
import { eventsSagas } from './events/sagas';
import { paymentsSagas } from './payments/sagas';
import { themeSagas } from './theme/sagas';
import { dashboardSagas } from './dashboard/sagas';
import { messengerSagas } from './messenger/sagas';
import { partenersSagas } from './parteners/sagas';
import { postsSagas } from './posts/sagas';

export function* watchSagas() {
    yield all([
        themeSagas(),
        authSagas(),
        usersSagas(),
        notificationsSagas(),
        eventsSagas(),
        paymentsSagas(),
        dashboardSagas(),
        messengerSagas(),
        partenersSagas(),
        postsSagas()
    ]);
}
