/* eslint-disable no-unused-vars */
// material-ui
import { Box, Typography } from '@mui/material';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    return (
        <Box sx={{ px: 1, pt: 0.3 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <a href="#qui" style={{ textDecoration: 'none' }}>
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        sx={{ my: 0.2, mx: 1, fontSize: '1.6rem', textTransform: 'uppercase', color: '#004b6e' }}
                    >
                        Qui
                    </Typography>
                </a>
                <a href="#pourquoi" style={{ textDecoration: 'none' }}>
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        sx={{ my: 0.2, mx: 1, fontSize: '1.6rem', textTransform: 'uppercase', color: '#68f90b' }}
                    >
                        Pourquoi
                    </Typography>
                </a>
                <a href="#adherez" style={{ textDecoration: 'none' }}>
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        sx={{ my: 0.2, mx: 1, fontSize: '1.6rem', textTransform: 'uppercase', color: '#004b6e' }}
                    >
                        Adherez
                    </Typography>
                </a>
                <a href="#blog" style={{ textDecoration: 'none' }}>
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        sx={{ my: 0.2, mx: 1, fontSize: '1.6rem', textTransform: 'uppercase', color: '#68f90b' }}
                    >
                        Blog
                    </Typography>
                </a>
                <a href="#amis" style={{ textDecoration: 'none' }}>
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        sx={{ my: 0.2, mx: 1, fontSize: '1.6rem', textTransform: 'uppercase', color: '#004b6e' }}
                    >
                        Amis
                    </Typography>
                </a>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <a href="#comment" style={{ textDecoration: 'none' }}>
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        sx={{ my: 0.2, mx: 1, fontSize: '1.6rem', textTransform: 'uppercase', color: '#68f90b' }}
                    >
                        Comment
                    </Typography>
                </a>
                <a href="#appli" style={{ textDecoration: 'none' }}>
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        sx={{ my: 0.2, mx: 1, fontSize: '1.6rem', textTransform: 'uppercase', color: '#004b6e' }}
                    >
                        L'Appli
                    </Typography>
                </a>
                <a href="#faq" style={{ textDecoration: 'none' }}>
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        sx={{ my: 0.2, mx: 1, fontSize: '1.6rem', textTransform: 'uppercase', color: '#68f90b' }}
                    >
                        Faq
                    </Typography>
                </a>
                <a href="#contact" style={{ textDecoration: 'none' }}>
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        sx={{ my: 0.2, mx: 1, fontSize: '1.6rem', textTransform: 'uppercase', color: '#004b6e' }}
                    >
                        Contact
                    </Typography>
                </a>
            </Box>
        </Box>
    );
};

export default MenuList;
