import * as TYPES from './types';

const initialState = {
    loading: true
};

export default function loadingReducer(state = initialState, action) {
    switch (action.type) {
        //Set Loading
        case TYPES.SET_LOADING_STATUS: {
            return {
                ...state,
                loading: action.payload
            };
        }
        default:
            return state;
    }
}
