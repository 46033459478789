// assets
import { IconUsers, IconCalendarEvent, IconSettings } from '@tabler/icons';

// constant
const icons = {
    IconUsers,
    IconCalendarEvent,
    IconSettings
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const system = {
    id: 'system',
    title: 'Système',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Utilisateurs de système',
            type: 'item',
            url: '/dashboard/users',
            icon: icons.IconUsers,
            breadcrumbs: true
        },
        {
            id: 'events',
            title: 'Jounral des événements',
            type: 'item',
            url: '/dashboard/events',
            icon: icons.IconCalendarEvent,
            breadcrumbs: true
        },
        {
            id: 'settings',
            title: 'Paramètres',
            type: 'item',
            url: '/dashboard/settings',
            icon: icons.IconSettings,
            breadcrumbs: true
        }
    ]
};

export default system;
