import * as TYPES from './types';

const initialState = {
    parteners: [],
    partener: {},
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    partenersStatistics: {}
};

export default function partenersReducer(state = initialState, action) {
    switch (action.type) {
        //Get parteners
        case TYPES.GET_PARTENERS_RECEIVE: {
            return {
                ...state,
                parteners: action.payload?.data?.data,
                pageCount: action.payload?.data?.total / 10,
                totalFiltred: action.payload?.data?.total,
                currentPage: action.payload?.data?.page
            };
        }
        //Get partener
        case TYPES.GET_PARTENER_RECEIVE: {
            return {
                ...state,
                partener: action.payload
            };
        }
        //Create partener
        case TYPES.CREATE_PARTENER_RECEIVE: {
            return {
                ...state,
                parteners: [...state.parteners, action.payload]
            };
        }
        //Update partener
        case TYPES.UPDATE_PARTENER_RECEIVE: {
            return {
                ...state,
                parteners: state.parteners.map((partener) => {
                    if (partener.id === action.payload.id) {
                        return action.payload;
                    }
                    return partener;
                })
            };
        }
        //Delete partener
        case TYPES.DELETE_PARTENER_RECEIVE: {
            return {
                ...state,

                parteners: state.parteners.filter((partener) => partener.id !== action.payload)
            };
        }
        //Get parteners list
        case TYPES.GET_PARTENERS_LIST_RECEIVE: {
            return {
                ...state,
                parteners: action.payload
            };
        }
        //Toggle partener status
        case TYPES.TOGGLE_PARTENER_STATUS_RECEIVE: {
            return {
                ...state,
                parteners: state.parteners.map((partener) => {
                    if (partener.id === action.payload) {
                        return {
                            ...partener,
                            isActive: !partener.isActive
                        };
                    }
                    return partener;
                })
            };
        }
        default:
            return state;
    }
}
