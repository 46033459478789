import * as TYPES from './types';

const initialState = {
    statistics: [],
    mostProfitableProfessionals: {},
    yearlyRevenue: {}
};

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        //Get statistics
        case TYPES.GET_STATISTICS_RECEIVE: {
            return {
                ...state,
                statistics: action.payload?.data
            };
        }
        //Get most profitable professionals
        case TYPES.GET_MOST_PROFITABLE_PROFESSIONALS_RECEIVE: {
            return {
                ...state,
                mostProfitableProfessionals: action.payload?.data
            };
        }
        //Get yearly revenue
        case TYPES.GET_YEARLY_REVENUE_RECEIVE: {
            return {
                ...state,
                yearlyRevenue: action.payload?.data
            };
        }
        default:
            return state;
    }
}
