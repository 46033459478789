export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_RECEIVE = 'LOGIN_RECEIVE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_RECEIVE = 'REGISTER_RECEIVE';

export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST';
export const FORGET_PASSWORD_RECEIVE = 'FORGET_PASSWORD_RECEIVE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_RECEIVE = 'RESET_PASSWORD_RECEIVE';

export const FORCE_PASSWORD_REQUEST = 'FORCE_PASSWORD_REQUEST';
export const FORCE_PASSWORD_RECEIVE = 'FORCE_PASSWORD_RECEIVE';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_RECEIVE = 'GET_PROFILE_RECEIVE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_RECEIVE = 'UPDATE_PROFILE_RECEIVE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_RECEIVE = 'CHANGE_PASSWORD_RECEIVE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_RECEIVE = 'LOGOUT_RECEIVE';

export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST';
export const CONTACT_US_RECEIVE = 'CONTACT_US_RECEIVE';
