import * as TYPES from './types';

// login
export const loginRequest = (payload) => ({
    type: TYPES.LOGIN_REQUEST,
    payload
});
export const loginReceive = (payload) => ({
    type: TYPES.LOGIN_RECEIVE,
    payload
});

// register
export const registerRequest = (payload) => ({
    type: TYPES.REGISTER_REQUEST,
    payload
});
export const registerReceive = (payload) => ({
    type: TYPES.REGISTER_RECEIVE,
    payload
});

// forget password
export const forgetPasswordRequest = (payload) => ({
    type: TYPES.FORGET_PASSWORD_REQUEST,
    payload
});
export const forgetPasswordReceive = (payload) => ({
    type: TYPES.FORGET_PASSWORD_RECEIVE,
    payload
});

// reset password
export const resetPasswordRequest = (payload) => ({
    type: TYPES.RESET_PASSWORD_REQUEST,
    payload
});
export const resetPasswordReceive = (payload) => ({
    type: TYPES.RESET_PASSWORD_RECEIVE,
    payload
});

// force password
export const forcePasswordRequest = (payload) => ({
    type: TYPES.FORCE_PASSWORD_REQUEST,
    payload
});
export const forcePasswordReceive = (payload) => ({
    type: TYPES.FORCE_PASSWORD_RECEIVE,
    payload
});
// get PROFILE
export const getProfileRequest = () => ({
    type: TYPES.GET_PROFILE_REQUEST
});
export const getProfileReceive = (payload) => ({
    type: TYPES.GET_PROFILE_RECEIVE,
    payload
});

//update PROFILE
export const updateProfileRequest = (payload) => ({
    type: TYPES.UPDATE_PROFILE_REQUEST,
    payload
});
export const updateProfileReceive = (payload) => ({
    type: TYPES.UPDATE_PROFILE_RECEIVE,
    payload
});

//Change Password
export const changePasswordRequest = (payload) => ({
    type: TYPES.CHANGE_PASSWORD_REQUEST,
    payload
});
export const changePasswordReceive = (payload) => ({
    type: TYPES.CHANGE_PASSWORD_RECEIVE,
    payload
});

// logout
export const logoutRequest = () => ({
    type: TYPES.LOGOUT_REQUEST
});
export const logoutReceive = () => ({
    type: TYPES.LOGOUT_RECEIVE
});

// contact us
export const contactUsRequest = (payload) => ({
    type: TYPES.CONTACT_US_REQUEST,
    payload
});
export const contactUsReceive = (payload) => ({
    type: TYPES.CONTACT_US_RECEIVE,
    payload
});
