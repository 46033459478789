import * as TYPES from './types';

const initialState = {
    events_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    event: {}
};

export default function eventsReducer(state = initialState, action) {
    switch (action.type) {
        //Get Events
        case TYPES.GET_EVENTS_RECEIVE: {
            return {
                ...state,
                events_list: action.payload?.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Get Event
        case TYPES.GET_EVENT_RECEIVE: {
            return {
                ...state,
                event: action.payload
            };
        }
        default:
            return state;
    }
}
