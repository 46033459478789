import { combineReducers } from 'redux';
import theme from './theme/reducer';
import auth from './auth/reducer';
import users from './users/reducer';
import loader from './loader/reducer';
import snackbar from './snackbar/reducer';
import notifications from './notifications/reducer';
import events from './events/reducer';
import payments from './payments/reducer';
import loading from './loading/reducer';
import dashboard from './dashboard/reducer';
import messenger from './messenger/reducer';
import parteners from './parteners/reducer';
import posts from './posts/reducer';

export default combineReducers({
    theme,
    snackbar,
    loader,
    auth,
    users,
    notifications,
    events,
    payments,
    loading,
    dashboard,
    messenger,
    parteners,
    posts
});
