import { axiosInstance } from '../../network';
//Parteners
//Get Parteners
export const getPartenersRequest = async (payload) => {
    return await axiosInstance.get(`api/parteners`, { params: payload });
};
//Get Partener
export const getPartenerRequest = async (payload) => {
    return await axiosInstance.get(`api/parteners/${payload}`);
};
//Create Partener
export const createPartenerRequest = async (payload) => {
    return await axiosInstance.post(`api/parteners`, payload);
};
//Update Partener
export const updatePartenerRequest = async (payload) => {
    return await axiosInstance.patch(`api/parteners/${payload.id}`, payload);
};
//Delete Partener
export const deletePartenerRequest = async (id) => {
    return await axiosInstance.delete(`api/parteners/${id}`);
};
//Get Parteners List
export const getPartenersListRequest = async () => {
    return await axiosInstance.get(`api/parteners/list`);
};
//Toggle Partener Status
export const togglePartenerStatusRequest = async (payload) => {
    return await axiosInstance.put(`api/parteners/${payload}/toggle-status`);
};
