/* eslint-disable no-unused-vars */
import { Outlet } from 'react-router-dom';

// project imports
import Customization from '../Customization';
import { MaterialSnackbar } from 'ui-component/Snackbar';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuRequest } from 'store/theme/actions';
import Footer from './Footer';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const theme = useTheme();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const leftDrawerOpened = useSelector((state) => state.theme.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch(setMenuRequest({ opened: !leftDrawerOpened }));
    };

    return (
        <>
            <MaterialSnackbar />
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    height: matchDownSm ? '90px' : '135px',
                    bgcolor: '#0a0615',
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>
            <Box sx={{ height: matchDownSm ? '90px' : '135px' }} />
            <Outlet />
            <Footer />
        </>
    );
};

export default MinimalLayout;
