import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Posts
//Get Posts
export function* getPostsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getPostsRequest, payload);
        yield put(ACTIONS.getPostsReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Post
export function* getPostSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getPostRequest, payload);
        yield put(ACTIONS.getPostReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Create Post
export function* createPostSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createPostRequest, payload);
        yield put(ACTIONS.createPostReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 1000);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Update Post
export function* updatePostSaga({ payload, closeModal }) {
    try {
        const { data } = yield call(APIS.updatePostRequest, payload);
        yield put(ACTIONS.updatePostReceive(data));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 1000);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Post
export function* deletePostSaga({ payload }) {
    try {
        yield call(APIS.deletePostRequest, payload);
        yield put(ACTIONS.deletePostReceive(payload));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Posts List
export function* getPostsListSaga() {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getPostsListRequest);
        yield put(ACTIONS.getPostsListReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Toggle Post Status
export function* togglePostStatusSaga({ payload }) {
    try {
        yield call(APIS.togglePostStatusRequest, payload);
        yield put(ACTIONS.togglePostStatusReceive(payload));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* postsSagas() {
    yield takeLatest(TYPES.GET_POSTS_REQUEST, getPostsSaga);
    yield takeLatest(TYPES.GET_POST_REQUEST, getPostSaga);
    yield takeLatest(TYPES.CREATE_POST_REQUEST, createPostSaga);
    yield takeLatest(TYPES.UPDATE_POST_REQUEST, updatePostSaga);
    yield takeLatest(TYPES.DELETE_POST_REQUEST, deletePostSaga);
    yield takeLatest(TYPES.GET_POSTS_LIST_REQUEST, getPostsListSaga);
    yield takeLatest(TYPES.TOGGLE_POST_STATUS_REQUEST, togglePostStatusSaga);
}
