//Get Users
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_RECEIVE = 'GET_USERS_RECEIVE';
//Get UserData
export const GET_USER_DATA_REQUEST = 'GET_USER_DATA_REQUEST';
export const GET_USER_DATA_RECEIVE = 'GET_USER_DATA_RECEIVE';
//Add User
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_RECEIVE = 'ADD_USER_RECEIVE';
//Edit User
export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_RECEIVE = 'EDIT_USER_RECEIVE';
//Delete User
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_RECEIVE = 'DELETE_USER_RECEIVE';
//Get Users List
export const GET_USERS_LIST_REQUEST = 'GET_USERS_LIST_REQUEST';
export const GET_USERS_LIST_RECEIVE = 'GET_USERS_LIST_RECEIVE';
//Get Users Statistics
export const GET_USERS_STATISTICS_REQUEST = 'GET_USERS_STATISTICS_REQUEST';
export const GET_USERS_STATISTICS_RECEIVE = 'GET_USERS_STATISTICS_RECEIVE';
//Toggle User Status
export const TOGGLE_USER_STATUS_REQUEST = 'TOGGLE_USER_STATUS_REQUEST';
export const TOGGLE_USER_STATUS_RECEIVE = 'TOGGLE_USER_STATUS_RECEIVE';
//Get Members List
export const GET_MEMBERS_LIST_REQUEST = 'GET_MEMBERS_LIST_REQUEST';
export const GET_MEMBERS_LIST_RECEIVE = 'GET_MEMBERS_LIST_RECEIVE';
//Set Iniiial State
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
