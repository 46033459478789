// assets
import {
    IconLayoutDashboard,
    IconCoinEuro,
    IconAd2,
    IconAdOff,
    IconChecklist,
    IconTools,
    IconUser,
    IconDiscount2,
    IconOutbound,
    IconBrandMessenger,
    IconNews
} from '@tabler/icons';

// constant
const icons = {
    IconLayoutDashboard,
    IconCoinEuro,
    IconAd2,
    IconAdOff,
    IconChecklist,
    IconTools,
    IconUser,
    IconDiscount2,
    IconOutbound,
    IconBrandMessenger,
    IconNews
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: "L'Apej Application",
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.IconLayoutDashboard,
            breadcrumbs: false
        },
        {
            id: 'members',
            title: 'Adhérents',
            type: 'item',
            url: '/dashboard/members',
            icon: icons.IconUser,
            breadcrumbs: true
        },
        {
            id: 'messenger',
            title: 'Messagerie',
            type: 'item',
            url: '/dashboard/messenger',
            icon: icons.IconBrandMessenger,
            breadcrumbs: true
        },
        {
            id: 'blog',
            title: 'Blogue',
            type: 'item',
            url: '/dashboard/blog',
            icon: icons.IconNews,
            breadcrumbs: true
        },
        {
            id: 'parteners',
            title: 'Partenaires',
            type: 'item',
            url: '/dashboard/parteners',
            icon: icons.IconDiscount2,
            breadcrumbs: true
        },
        {
            id: 'incomes',
            title: 'Revenus',
            type: 'item',
            url: '/dashboard/incomes',
            icon: icons.IconCoinEuro,
            breadcrumbs: true
        },
        {
            id: 'expenses',
            title: 'Dépenses',
            type: 'item',
            url: '/dashboard/expenses',
            icon: icons.IconOutbound,
            breadcrumbs: true
        }
    ]
};

export default dashboard;
