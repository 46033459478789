import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Parteners
//Get Parteners
export function* getPartenersSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getPartenersRequest, payload);
        yield put(ACTIONS.getPartenersReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Partener
export function* getPartenerSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getPartenerRequest, payload);
        yield put(ACTIONS.getPartenerReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Create Partener
export function* createPartenerSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createPartenerRequest, payload);
        yield put(ACTIONS.createPartenerReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 1000);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Update Partener
export function* updatePartenerSaga({ payload, closeModal }) {
    try {
        const { data } = yield call(APIS.updatePartenerRequest, payload);
        yield put(ACTIONS.updatePartenerReceive(data));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 1000);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Partener
export function* deletePartenerSaga({ payload }) {
    try {
        yield call(APIS.deletePartenerRequest, payload);
        yield put(ACTIONS.deletePartenerReceive(payload));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Parteners List
export function* getPartenersListSaga() {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getPartenersListRequest);
        yield put(ACTIONS.getPartenersListReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Toggle Partener Status
export function* togglePartenerStatusSaga({ payload }) {
    try {
        yield call(APIS.togglePartenerStatusRequest, payload);
        yield put(ACTIONS.togglePartenerStatusReceive(payload));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* partenersSagas() {
    yield takeLatest(TYPES.GET_PARTENERS_REQUEST, getPartenersSaga);
    yield takeLatest(TYPES.GET_PARTENER_REQUEST, getPartenerSaga);
    yield takeLatest(TYPES.CREATE_PARTENER_REQUEST, createPartenerSaga);
    yield takeLatest(TYPES.UPDATE_PARTENER_REQUEST, updatePartenerSaga);
    yield takeLatest(TYPES.DELETE_PARTENER_REQUEST, deletePartenerSaga);
    yield takeLatest(TYPES.GET_PARTENERS_LIST_REQUEST, getPartenersListSaga);
    yield takeLatest(TYPES.TOGGLE_PARTENER_STATUS_REQUEST, togglePartenerStatusSaga);
}
