//Parteners
//Get Parteners
export const GET_PARTENERS_REQUEST = 'GET_PARTENERS_REQUEST';
export const GET_PARTENERS_RECEIVE = 'GET_PARTENERS_RECEIVE';
//Get Partener
export const GET_PARTENER_REQUEST = 'GET_PARTENER_REQUEST';
export const GET_PARTENER_RECEIVE = 'GET_PARTENER_RECEIVE';
//Create Partener
export const CREATE_PARTENER_REQUEST = 'CREATE_PARTENER_REQUEST';
export const CREATE_PARTENER_RECEIVE = 'CREATE_PARTENER_RECEIVE';
//Update Partener
export const UPDATE_PARTENER_REQUEST = 'UPDATE_PARTENER_REQUEST';
export const UPDATE_PARTENER_RECEIVE = 'UPDATE_PARTENER_RECEIVE';
//Delete Partener
export const DELETE_PARTENER_REQUEST = 'DELETE_PARTENER_REQUEST';
export const DELETE_PARTENER_RECEIVE = 'DELETE_PARTENER_RECEIVE';
//Get Parteners List
export const GET_PARTENERS_LIST_REQUEST = 'GET_PARTENERS_LIST_REQUEST';
export const GET_PARTENERS_LIST_RECEIVE = 'GET_PARTENERS_LIST_RECEIVE';
//Toggle Partener Status
export const TOGGLE_PARTENER_STATUS_REQUEST = 'TOGGLE_PARTENER_STATUS_REQUEST';
export const TOGGLE_PARTENER_STATUS_RECEIVE = 'TOGGLE_PARTENER_STATUS_RECEIVE';
