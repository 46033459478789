import { axiosInstance } from '../../network';
//Posts
//Get Posts
export const getPostsRequest = async (payload) => {
    return await axiosInstance.get(`api/posts`, { params: payload });
};
//Get Post
export const getPostRequest = async (payload) => {
    return await axiosInstance.get(`api/posts/${payload}`);
};
//Create Post
export const createPostRequest = async (payload) => {
    return await axiosInstance.post(`api/posts`, payload);
};
//Update Post
export const updatePostRequest = async (payload) => {
    return await axiosInstance.patch(`api/posts/${payload.id}`, payload);
};
//Delete Post
export const deletePostRequest = async (id) => {
    return await axiosInstance.delete(`api/posts/${id}`);
};
//Get Posts List
export const getPostsListRequest = async () => {
    return await axiosInstance.get(`api/posts/list`);
};
//Toggle Post Status
export const togglePostStatusRequest = async (payload) => {
    return await axiosInstance.put(`api/posts/${payload}/toggle-status`);
};
