// material-ui
//import { useTheme } from '@mui/material/styles';
//import logoDark from 'assets/images/logo-dark.png';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import logo from 'assets/images/logo.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    //const theme = useTheme();
    const theme = useTheme();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    return <img id="app-logo" src={logo} alt="Berry" width={matchDownSm ? 60 : 100} />;
};

export default Logo;
