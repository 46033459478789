import * as TYPES from './types';

// Get Chat Rooms
export const getChatRoomsRequest = (payload) => ({
    type: TYPES.GET_CHAT_ROOMS_REQUEST,
    payload
});
export const getChatRoomsReceive = (payload) => ({
    type: TYPES.GET_CHAT_ROOMS_RECEIVE,
    payload
});
////Get Chat Room
export const getChatRoomRequest = (payload) => ({
    type: TYPES.GET_CHAT_ROOM_REQUEST,
    payload
});
export const getChatRoomReceive = (payload) => ({
    type: TYPES.GET_CHAT_ROOM_RECEIVE,
    payload
});
//Create message
export const createMessageRequest = (payload, resetMessage) => ({
    type: TYPES.CREATE_MESSAGE_REQUEST,
    payload,
    resetMessage
});
export const createMessageReceive = (payload, resetMessage) => ({
    type: TYPES.CREATE_MESSAGE_RECEIVE,
    payload,
    resetMessage
});
