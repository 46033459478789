/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import AOS from 'aos';
import 'aos/dist/aos.css';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.theme);
    const { token } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({
            easing: 'ease-out-cubic',
            once: true,
            offset: 50,
            delay: 200,
            duration: 1200
        });
    }, []);

    useEffect(() => {
        if (token) {
            if (window.location.pathname === '/') {
                navigate('/dashboard', { replace: true });
            }
        } else {
            console.log(window.location.pathname);
            if (
                window.location.pathname !== '/home' &&
                window.location.pathname !== '/login' &&
                //window.location.pathname !== '/register' &&
                window.location.pathname !== '/forgot' &&
                window.location.pathname !== '/reset' &&
                window.location.pathname !== '/reset-success' &&
                window.location.pathname !== '/privacy' &&
                window.location.pathname !== '/contact'
            ) {
                navigate('/home', { replace: true });
            }
        }
        //console.log(window.location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, window.location.pathname, token]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
