import * as TYPES from './types';

// Get Statistics
export const getStatisticsRequest = (payload) => ({
    type: TYPES.GET_STATISTICS_REQUEST,
    payload
});
export const getStatisticsReceive = (payload) => ({
    type: TYPES.GET_STATISTICS_RECEIVE,
    payload
});
////Get Most Profitable Professionals
export const getMostProfitableProfessionalsRequest = (payload) => ({
    type: TYPES.GET_MOST_PROFITABLE_PROFESSIONALS_REQUEST,
    payload
});
export const getMostProfitableProfessionalsReceive = (payload) => ({
    type: TYPES.GET_MOST_PROFITABLE_PROFESSIONALS_RECEIVE,
    payload
});
//Get yearly revenue
export const getYearlyRevenueRequest = (payload) => ({
    type: TYPES.GET_YEARLY_REVENUE_REQUEST,
    payload
});
export const getYearlyRevenueReceive = (payload) => ({
    type: TYPES.GET_YEARLY_REVENUE_RECEIVE,
    payload
});
