/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Members = Loadable(lazy(() => import('views/pages/members/Members')));
const Messenger = Loadable(lazy(() => import('views/pages/messenger/Messenger')));
const Posts = Loadable(lazy(() => import('views/pages/posts/Posts')));
const Parteners = Loadable(lazy(() => import('views/pages/parteners/Parteners')));
const Incomes = Loadable(lazy(() => import('views/pages/incomes/Incomes')));
const Expenses = Loadable(lazy(() => import('views/pages/expenses/Expenses')));
const Profile = Loadable(lazy(() => import('views/pages/profile/Profile')));

//Users routing
const Users = Loadable(lazy(() => import('views/pages/users/Users')));
// Settings Routes
const EventsPage = Loadable(lazy(() => import('views/pages/events/EventsPage')));
const Settings = Loadable(lazy(() => import('views/pages/settings')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/dashboard',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: 'members',
            element: <Members />
        },
        {
            path: 'messenger',
            element: <Messenger />
        },
        {
            path: 'blog',
            element: <Posts />
        },
        {
            path: 'parteners',
            element: <Parteners />
        },
        {
            path: 'incomes',
            element: <Incomes />
        },
        {
            path: 'expenses',
            element: <Expenses />
        },
        {
            path: 'users',
            element: <Users />
        },
        {
            path: 'events',
            element: <EventsPage />
        },
        {
            path: 'settings',
            element: <Settings />
        },
        {
            path: 'profile',
            element: <Profile />
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
