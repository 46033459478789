import { axiosInstance } from '../../network';

//Get Events
export const getEventsRequest = async (payload) => {
    return await axiosInstance.get(`api/events`, { params: payload });
};
//Get Event
export const getEventRequest = async (payload) => {
    return await axiosInstance.get(`api/events/${payload}`);
};
