import config from 'config';
import * as TYPES from './types';

export const initialState = {
    isOpen: [], // for active default menu
    defaultId: 'default',
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true
};

export default function themeReducer(state = initialState, action) {
    switch (action.type) {
        case TYPES.MENU_OPEN_RECEIVE:
            return {
                ...state,
                isOpen: [action.payload?.id]
            };
        case TYPES.SET_MENU_RECEIVE:
            return {
                ...state,
                opened: action.payload?.opened
            };
        case TYPES.SET_FONT_FAMILY_RECEIVE:
            return {
                ...state,
                fontFamily: action.payload?.fontFamily
            };
        case TYPES.SET_BORDER_RADIUS_RECEIVE:
            return {
                ...state,
                borderRadius: action.payload?.borderRadius
            };
        default:
            return state;
    }
}
