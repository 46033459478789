/* eslint-disable no-unused-vars */
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
    const theme = useTheme();
    const { notifications_list } = useSelector((state) => state.notifications);

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light
    };

    const chipPrimarySX = {
        ...chipSX,
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light,
        height: 28
    };

    return (
        <List
            sx={{
                width: '100%',
                minWidth: 350,
                maxWidth: 380,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    minWidth: 290,
                    maxWidth: 320
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 15,
                    right: 5
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            <ListItemWrapper>
                {notifications_list?.map((notification, index) => (
                    <div key={index}>
                        <ListItemWrapper>
                            <ListItem alignItems="space-between">
                                <ListItemText sx={{ width: '80%' }} primary={notification.title} secondary={notification.description} />
                                <ListItemSecondaryAction>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={12} display="flex" justifyContent="flex-end">
                                            <Typography variant="caption" display="block" gutterBottom>
                                                {moment(notification.createdAt).fromNow()}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container display="flex" justifyContent="flex-end">
                                                {notification.seen ? (
                                                    <Grid item>
                                                        <Chip label="Lu" sx={chipPrimarySX} />
                                                    </Grid>
                                                ) : (
                                                    <Grid item>
                                                        <Chip label="Nouvelle" sx={chipWarningSX} />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </ListItemWrapper>
                        <Divider sx={{ height: '20px' }} />
                    </div>
                ))}
            </ListItemWrapper>
        </List>
    );
};

export default NotificationList;
