import * as TYPES from './types';
//Parteners
// Get Parteners
export const getPartenersRequest = (payload) => ({
    type: TYPES.GET_PARTENERS_REQUEST,
    payload
});
export const getPartenersReceive = (payload) => ({
    type: TYPES.GET_PARTENERS_RECEIVE,
    payload
});
//Get Partener
export const getPartenerRequest = (payload) => ({
    type: TYPES.GET_PARTENER_REQUEST,
    payload
});
export const getPartenerReceive = (payload) => ({
    type: TYPES.GET_PARTENER_RECEIVE,
    payload
});
//Create Partener
export const createPartenerRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_PARTENER_REQUEST,
    payload,
    closeModal
});
export const createPartenerReceive = (payload) => ({
    type: TYPES.CREATE_PARTENER_RECEIVE,
    payload
});
//Update Partener
export const updatePartenerRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_PARTENER_REQUEST,
    payload,
    closeModal
});
export const updatePartenerReceive = (payload) => ({
    type: TYPES.UPDATE_PARTENER_RECEIVE,
    payload
});
//Delete Partener
export const deletePartenerRequest = (payload) => ({
    type: TYPES.DELETE_PARTENER_REQUEST,
    payload
});
export const deletePartenerReceive = (payload) => ({
    type: TYPES.DELETE_PARTENER_RECEIVE,
    payload
});
//Get Parteners List
export const getPartenersListRequest = (payload) => ({
    type: TYPES.GET_PARTENERS_LIST_REQUEST,
    payload
});
export const getPartenersListReceive = (payload) => ({
    type: TYPES.GET_PARTENERS_LIST_RECEIVE,
    payload
});
//Toggle Partener Status
export const togglePartenerStatusRequest = (payload) => ({
    type: TYPES.TOGGLE_PARTENER_STATUS_REQUEST,
    payload
});
export const togglePartenerStatusReceive = (payload) => ({
    type: TYPES.TOGGLE_PARTENER_STATUS_RECEIVE,
    payload
});
