import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get Users
export function* getUsersSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getUsersRequest, payload);
        yield put(ACTIONS.getUsersReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get UserData
export function* getUserDataSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getUserDataRequest, id);
        yield put(ACTIONS.getUserDataReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Add User
export function* addUserSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.addUserRequest, payload);
        yield put(ACTIONS.addUserReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit User
export function* editUserSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editUserRequest, payload);
        yield put(ACTIONS.editUserReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete User
export function* deleteUserSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteUserRequest, id);
        yield put(ACTIONS.deleteUserReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Users List
export function* getUsersListSaga({ payload }) {
    try {
        const { data } = yield call(APIS.getUsersListRequest, payload);
        yield put(ACTIONS.getUsersListReceive(data));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Users Statistics
export function* getUsersStatisticsSaga({ payload }) {
    try {
        const { data } = yield call(APIS.getUsersStatisticsRequest, payload);
        yield put(ACTIONS.getUsersStatisticsReceive(data));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Toggle User Status
export function* toggleUserStatusSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.toggleUserStatusRequest, id);
        yield put(ACTIONS.toggleUserStatusReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Members List
export function* getMembersListSaga({ payload }) {
    try {
        const { data } = yield call(APIS.getMembersListRequest, payload);
        yield put(ACTIONS.getMembersListReceive(data));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* usersSagas() {
    yield takeLatest(TYPES.GET_USERS_REQUEST, getUsersSaga);
    yield takeLatest(TYPES.GET_USER_DATA_REQUEST, getUserDataSaga);
    yield takeLatest(TYPES.ADD_USER_REQUEST, addUserSaga);
    yield takeLatest(TYPES.EDIT_USER_REQUEST, editUserSaga);
    yield takeLatest(TYPES.DELETE_USER_REQUEST, deleteUserSaga);
    yield takeLatest(TYPES.GET_USERS_LIST_REQUEST, getUsersListSaga);
    yield takeLatest(TYPES.GET_USERS_STATISTICS_REQUEST, getUsersStatisticsSaga);
    yield takeLatest(TYPES.TOGGLE_USER_STATUS_REQUEST, toggleUserStatusSaga);
    yield takeLatest(TYPES.GET_MEMBERS_LIST_REQUEST, getMembersListSaga);
}
