import * as TYPES from './types';

// Get Notifications
export const getNotificationsRequest = (payload) => ({
    type: TYPES.GET_NOTIFICATIONS_REQUEST,
    payload
});
export const getNotificationsReceive = (payload) => ({
    type: TYPES.GET_NOTIFICATIONS_RECEIVE,
    payload
});

// Seen Notifications
export const seenNotificationsRequest = (payload) => ({
    type: TYPES.SEEN_NOTIFICATIONS_REQUEST,
    payload
});
export const seenNotificationsReceive = (payload) => ({
    type: TYPES.SEEN_NOTIFICATIONS_RECEIVE,
    payload
});
