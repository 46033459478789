export const SET_MENU_REQUEST = 'SET_MENU_REQUEST';
export const SET_MENU_RECEIVE = 'SET_MENU_RECEIVE';

export const MENU_OPEN_REQUEST = 'MENU_OPEN_REQUEST';
export const MENU_OPEN_RECEIVE = 'MENU_OPEN_RECEIVE';

export const SET_FONT_FAMILY_REQUEST = 'SET_FONT_FAMILY_REQUEST';
export const SET_FONT_FAMILY_RECEIVE = 'SET_FONT_FAMILY_RECEIVE';

export const SET_BORDER_RADIUS_REQUEST = 'SET_BORDER_RADIUS_REQUEST';
export const SET_BORDER_RADIUS_RECEIVE = 'SET_BORDER_RADIUS_RECEIVE';
