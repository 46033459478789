//Payments
//Get Payments
export const GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST';
export const GET_PAYMENTS_RECEIVE = 'GET_PAYMENTS_RECEIVE';
//Get Payment
export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST';
export const GET_PAYMENT_RECEIVE = 'GET_PAYMENT_RECEIVE';
//Create Payment
export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_RECEIVE = 'CREATE_PAYMENT_RECEIVE';
//Update Payment
export const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST';
export const UPDATE_PAYMENT_RECEIVE = 'UPDATE_PAYMENT_RECEIVE';
//Delete Payment
export const DELETE_PAYMENT_REQUEST = 'DELETE_PAYMENT_REQUEST';
export const DELETE_PAYMENT_RECEIVE = 'DELETE_PAYMENT_RECEIVE';
//Refund Payment
export const REFUND_PAYMENT_REQUEST = 'REFUND_PAYMENT_REQUEST';
export const REFUND_PAYMENT_RECEIVE = 'REFUND_PAYMENT_RECEIVE';
