import * as TYPES from './types';

// Get Events
export const getEventsRequest = (payload) => ({
    type: TYPES.GET_EVENTS_REQUEST,
    payload
});
export const getEventsReceive = (payload) => ({
    type: TYPES.GET_EVENTS_RECEIVE,
    payload
});
// Get Event
export const getEventRequest = (payload) => ({
    type: TYPES.GET_EVENT_REQUEST,
    payload
});
export const getEventReceive = (payload) => ({
    type: TYPES.GET_EVENT_RECEIVE,
    payload
});
