import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import {
    dispatchSnackbarError
    // dispatchSnackbarSuccess,
} from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get Events
export function* getEventsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getEventsRequest, payload);
        yield put(ACTIONS.getEventsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Event
export function* getEventSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getEventRequest, payload);
        yield put(ACTIONS.getEventReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* eventsSagas() {
    yield takeLatest(TYPES.GET_EVENTS_REQUEST, getEventsSaga);
    yield takeLatest(TYPES.GET_EVENT_REQUEST, getEventSaga);
}
