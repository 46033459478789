import * as TYPES from './types';

// set menu
export const setMenuRequest = (payload) => ({
    type: TYPES.SET_MENU_REQUEST,
    payload
});
export const setMenuReceive = (payload) => ({
    type: TYPES.SET_MENU_RECEIVE,
    payload
});

// menu open
export const menuOpenRequest = (payload) => ({
    type: TYPES.MENU_OPEN_REQUEST,
    payload
});
export const menuOpenReceive = (payload) => ({
    type: TYPES.MENU_OPEN_RECEIVE,
    payload
});

// set font family
export const setFontFamilyRequest = (payload) => ({
    type: TYPES.SET_FONT_FAMILY_REQUEST,
    payload
});
export const setFontFamilyReceive = (payload) => ({
    type: TYPES.SET_FONT_FAMILY_RECEIVE,
    payload
});

// set border radius
export const setBorderRadiusRequest = (payload) => ({
    type: TYPES.SET_BORDER_RADIUS_REQUEST,
    payload
});
export const setBorderRadiusReceive = (payload) => ({
    type: TYPES.SET_BORDER_RADIUS_RECEIVE,
    payload
});
