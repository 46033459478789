import { axiosInstance } from '../../network';

//Get Requests
export const getStatisticsRequest = async (payload) => {
    return await axiosInstance.get(`api/dashboard/statistics`, { params: payload });
};
//Get Most Profitable Professionals
export const getMostProfitableProfessionalsRequest = async (payload) => {
    return await axiosInstance.get(`api/dashboard/most-profitable-professionals`, { params: payload });
};
//Get Yearly Revenue
export const getYearlyRevenueRequest = async (payload) => {
    return await axiosInstance.get(`api/dashboard/yearly-revenue`, { params: payload });
};
