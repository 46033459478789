import * as TYPES from './types';

const initialState = {
    chatRooms: [],
    chatRoom: {}
};

export default function messengerReducer(state = initialState, action) {
    switch (action.type) {
        //Get Chat Rooms
        case TYPES.GET_CHAT_ROOMS_RECEIVE: {
            return {
                ...state,
                chatRooms: action.payload?.data
            };
        }
        //Get Chat Room
        case TYPES.GET_CHAT_ROOM_RECEIVE: {
            return {
                ...state,
                chatRoom: action.payload?.data
            };
        }
        //Create message
        case TYPES.CREATE_MESSAGE_RECEIVE: {
            return {
                ...state,
                chatRoom: action.payload?.data
            };
        }
        default:
            return state;
    }
}
