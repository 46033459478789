import { axiosInstance } from '../../network';

// login
export const login = async (payload) => {
    return await axiosInstance.post('api/auth/login', payload);
};

// register
export const register = async (payload) => {
    return await axiosInstance.post('api/auth/register', payload);
};

// forget password
export const forgetPassword = async (payload) => {
    return await axiosInstance.post('api/auth/forget-password', payload);
};

// reset password
export const resetPassword = async (payload) => {
    return await axiosInstance.post(`api/auth/reset-password/${payload.id}`, payload);
};

// force password
export const forcePassword = async (payload) => {
    return await axiosInstance.post('api/auth/force-password', payload);
};

// get profile
export const getProfile = async () => {
    return await axiosInstance.get('api/auth/profile');
};

// update profile
export const updateProfile = async (payload) => {
    return await axiosInstance.post('api/auth/update-profile', payload);
};

// change password
export const changePassword = async (payload) => {
    return await axiosInstance.post('api/auth/change-password', payload);
};

// logout
export const logout = async () => {
    return await axiosInstance.post('api/auth/logout');
};
//Contact us
export const contactUs = async (payload) => {
    return await axiosInstance.post('api/auth/contact-us', payload);
};
