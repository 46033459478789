import * as TYPES from './types';

const initialState = {
    posts: [],
    post: {},
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    postsStatistics: {}
};

export default function postsReducer(state = initialState, action) {
    switch (action.type) {
        //Get posts
        case TYPES.GET_POSTS_RECEIVE: {
            return {
                ...state,
                posts: action.payload?.data?.data,
                pageCount: action.payload?.data?.total / 10,
                totalFiltred: action.payload?.data?.total,
                currentPage: action.payload?.data?.page
            };
        }
        //Get post
        case TYPES.GET_POST_RECEIVE: {
            return {
                ...state,
                post: action.payload
            };
        }
        //Create post
        case TYPES.CREATE_POST_RECEIVE: {
            return {
                ...state,
                posts: [...state.posts, action.payload]
            };
        }
        //Update post
        case TYPES.UPDATE_POST_RECEIVE: {
            return {
                ...state,
                posts: state.posts.map((post) => {
                    if (post.id === action.payload.id) {
                        return action.payload;
                    }
                    return post;
                })
            };
        }
        //Delete post
        case TYPES.DELETE_POST_RECEIVE: {
            return {
                ...state,

                posts: state.posts.filter((post) => post.id !== action.payload)
            };
        }
        //Get posts list
        case TYPES.GET_POSTS_LIST_RECEIVE: {
            return {
                ...state,
                posts: action.payload
            };
        }
        //Toggle post status
        case TYPES.TOGGLE_POST_STATUS_RECEIVE: {
            return {
                ...state,
                posts: state.posts.map((post) => {
                    if (post.id === action.payload) {
                        return {
                            ...post,
                            isActive: !post.isActive
                        };
                    }
                    return post;
                })
            };
        }
        default:
            return state;
    }
}
