// material-ui
//import { useTheme } from '@mui/material/styles';
//import logoDark from 'assets/images/logo-dark.png';
import logo from 'assets/images/jaures.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const SecondaryLogo = () => {
    //const theme = useTheme();

    return <img id="app-logo" src={logo} alt="Berry" width={180} />;
};

export default SecondaryLogo;
