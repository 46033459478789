/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Typography, useMediaQuery } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import SecondaryLogo from 'ui-component/SecondaryLogo';
import MenuList from '../MenuList';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="div">
                    <LogoSection />
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <Typography
                    variant="h4"
                    color="inherit"
                    sx={{
                        textAlign: 'center',
                        mx: 1,
                        fontSize: { xs: '0.9rem', sm: '1.1rem' },
                        textTransform: 'uppercase',
                        color: '#68f90b'
                    }}
                >
                    L'APEJ: L'association des parents d'élèves
                    {!matchDownSm && <br />} du groupe scolaire Jean Jaurès
                </Typography>
            </Box>
            {/* Menu */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                <MenuList />
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box component="div" sx={{ display: { xs: 'none', sm: 'block' }, flexGrow: 1 }}>
                <SecondaryLogo />
            </Box>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
