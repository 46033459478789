import * as TYPES from './types';
//Posts
// Get Posts
export const getPostsRequest = (payload) => ({
    type: TYPES.GET_POSTS_REQUEST,
    payload
});
export const getPostsReceive = (payload) => ({
    type: TYPES.GET_POSTS_RECEIVE,
    payload
});
//Get Post
export const getPostRequest = (payload) => ({
    type: TYPES.GET_POST_REQUEST,
    payload
});
export const getPostReceive = (payload) => ({
    type: TYPES.GET_POST_RECEIVE,
    payload
});
//Create Post
export const createPostRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_POST_REQUEST,
    payload,
    closeModal
});
export const createPostReceive = (payload) => ({
    type: TYPES.CREATE_POST_RECEIVE,
    payload
});
//Update Post
export const updatePostRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_POST_REQUEST,
    payload,
    closeModal
});
export const updatePostReceive = (payload) => ({
    type: TYPES.UPDATE_POST_RECEIVE,
    payload
});
//Delete Post
export const deletePostRequest = (payload) => ({
    type: TYPES.DELETE_POST_REQUEST,
    payload
});
export const deletePostReceive = (payload) => ({
    type: TYPES.DELETE_POST_RECEIVE,
    payload
});
//Get Posts List
export const getPostsListRequest = (payload) => ({
    type: TYPES.GET_POSTS_LIST_REQUEST,
    payload
});
export const getPostsListReceive = (payload) => ({
    type: TYPES.GET_POSTS_LIST_RECEIVE,
    payload
});
//Toggle Post Status
export const togglePostStatusRequest = (payload) => ({
    type: TYPES.TOGGLE_POST_STATUS_REQUEST,
    payload
});
export const togglePostStatusReceive = (payload) => ({
    type: TYPES.TOGGLE_POST_STATUS_RECEIVE,
    payload
});
