/* eslint-disable no-unused-vars */
import { Box, Typography } from '@mui/material';
import React from 'react';

const Footer = () => {
    return (
        <Box
            sx={{
                background: '#000',
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 3
            }}
        >
            <Typography variant="h6" sx={{ color: '#fff' }}>
                L'Apej {new Date().getFullYear()} &copy; Tous droits réservés. Développé par{' '}
                <a href="https://creo.tn" target="_blank" rel="noreferrer">
                    Creo
                </a>
            </Typography>
        </Box>
    );
};

export default Footer;
