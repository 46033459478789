//const  myBaseUrl = "http://128.199.32.128/";

import moment from 'moment';

//const myBaseUrl = "http://localhost:3000/";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const IS_TN = process.env.REACT_APP_IS_TN === 'true' ?? true;

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 275;
export const appDrawerWidth = 320;

const ALL = 'ALL';

// USERS ROLES
const USER = 'USER';
const ADMIN = 'ADMIN';
const MEMBER = 'MEMBER';

// USERS STATUS
const ACTIVE = 'ACTIVE';
const BLOCKED = 'BLOCKED';
const INACTIVE = 'INACTIVE';
const DELETED = 'DELETED';

// Payment Types
const IN = 'IN';
const OUT = 'OUT';

// Payment Status
const PAID = 'PAID';
const PENDING = 'PENDING';
const CANCELED = 'CANCELED';

export const usersRoles = [
    { id: USER, name: 'Utilisateur' },
    { id: ADMIN, name: 'Administrateur' },
    { id: MEMBER, name: 'Adhérent' }
];

export const usersStatus = [
    { id: ALL, name: 'Tous' },
    { id: ACTIVE, name: 'Actif' },
    { id: BLOCKED, name: 'Bloqué' },
    { id: INACTIVE, name: 'Inactif' },
    { id: DELETED, name: 'Supprimé' }
];

export const partenersStatus = [
    { id: ALL, name: 'Tous' },
    { id: true, name: 'Actif' },
    { id: false, name: 'Inactif' }
];

export const paymentStatus = [
    { id: PAID, name: 'Payé' },
    { id: PENDING, name: 'En attente' },
    { id: CANCELED, name: 'Annulé' }
];

export const paymentTypes = [
    { id: IN, name: 'Entrée' },
    { id: OUT, name: 'Sortie' }
];

export const initialAddress = {
    id: null,
    address: '',
    lat: '',
    lng: ''
};

export const initialImage = {
    id: null,
    filename: '',
    mimetype: '',
    encoding: ''
};

export const initialChild = {
    id: null,
    name: '',
    age: 0,
    school: ''
};

export const initialUser = {
    id: null,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    role: '',
    status: ACTIVE,
    isVerified: true,
    otpVerified: true,
    image: initialImage,
    address: initialAddress,
    children: [initialChild]
};

export const initialProfile = {
    id: null,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    image: initialImage,
    address: initialAddress,
    changePassword: false,
    newPassword: ''
};

export const initialPartener = {
    id: null,
    name: '',
    email: '',
    phone: '',
    discount: 0,
    description: '',
    address: initialAddress,
    image: initialImage,
    isActive: true
};

export const initialRevenue = {
    id: null,
    type: 'IN',
    date: moment().format('YYYY-MM-DDTHH:MM'),
    amount: 0,
    notes: '',
    transactionId: '',
    userId: '0',
    status: 'PAID',
    username: ''
};

export const initialExpense = {
    id: null,
    type: 'OUT',
    date: moment().format('YYYY-MM-DDTHH:MM'),
    amount: 0,
    notes: '',
    transactionId: '',
    userId: '0',
    status: 'PAID',
    username: ''
};

export const encodeUserNameAndPass = (userName, password) => {
    var decodedStringBtoA = `[userName]${userName}[password]${password}`;
    return btoa(decodedStringBtoA);
};

export const decodeUserNameAndPass = (str) => {
    return atob(str);
};

export const fileSupportedFormats = ['image/JPEG', 'image/JPG', 'image/jpg', 'image/png', 'image/PNG', 'image/jpeg'];

export const arabicMonths = ['يناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر', 'اكتوبر', 'نوفمبر', 'ديسمبر'];

export const arabicDays = ['الاحد', 'الاثنين', 'الثلاثاء', 'الاربعاء', 'الخميس', 'الجمعة', 'السبت'];
