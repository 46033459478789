import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get Chat Rooms
export function* getChatRoomsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getChatRooms, payload);
        yield put(ACTIONS.getChatRoomsReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Chat Room
export function* getChatRoomSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getChatRoom, payload);
        yield put(ACTIONS.getChatRoomReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Create message
export function* createMessageSaga({ payload, resetMessage }) {
    try {
        const { data } = yield call(APIS.createMessage, payload);
        yield put(ACTIONS.createMessageReceive({ data }));
        setTimeout(() => {
            resetMessage('');
        }, 100);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* messengerSagas() {
    yield takeLatest(TYPES.GET_CHAT_ROOMS_REQUEST, getChatRoomsSaga);
    yield takeLatest(TYPES.GET_CHAT_ROOM_REQUEST, getChatRoomSaga);
    yield takeLatest(TYPES.CREATE_MESSAGE_REQUEST, createMessageSaga);
}
