import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get Statistics
export function* getStatisticsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getStatisticsRequest, payload);
        yield put(ACTIONS.getStatisticsReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* getMostProfitableProfessionalsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getMostProfitableProfessionalsRequest, payload);
        yield put(ACTIONS.getMostProfitableProfessionalsReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get yearly revenue
export function* getYearlyRevenueSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getYearlyRevenueRequest, payload);
        yield put(ACTIONS.getYearlyRevenueReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* dashboardSagas() {
    yield takeLatest(TYPES.GET_STATISTICS_REQUEST, getStatisticsSaga);
    yield takeLatest(TYPES.GET_MOST_PROFITABLE_PROFESSIONALS_REQUEST, getMostProfitableProfessionalsSaga);
    yield takeLatest(TYPES.GET_YEARLY_REVENUE_REQUEST, getYearlyRevenueSaga);
}
