import { call, put, takeLatest } from 'redux-saga/effects';
import * as API from './api';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';

// Login
export function* login({ payload }) {
    try {
        const response = yield call(API.login, payload);
        yield put(ACTIONS.loginReceive(response?.data));
        if (response?.status === 200) {
            localStorage.setItem('user', JSON.stringify(response?.data?.user));
            localStorage.setItem('token', response?.data?.access_token);
        }
    } catch (err) {
        console.log(err);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        dispatchSnackbarError(err?.response?.data);
    }
}

export function* register({ payload }) {
    try {
        const response = yield call(API.register, payload);
        yield put(ACTIONS.registerReceive(response?.data));
        if (response?.status === 200) {
            localStorage.setItem('user', JSON.stringify(response?.data?.user));
            localStorage.setItem('token', response?.data?.token);
        }
    } catch (err) {
        console.log(err.response.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* forgetPassword({ payload }) {
    try {
        const response = yield call(API.forgetPassword, payload);
        yield put(ACTIONS.forgetPasswordReceive(response.data));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err.response.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* resetPassword({ payload }) {
    try {
        const response = yield call(API.resetPassword, { id: payload.id, password: payload.password });
        yield put(ACTIONS.resetPasswordReceive(response.data));
        if (response?.status === 200) {
            localStorage.setItem('reset_password', true);
            payload.navigate('/reset-success');
        }
    } catch (err) {
        console.log(err.response.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* forcePassword({ payload }) {
    try {
        yield call(API.forcePassword, payload);
        yield put(ACTIONS.forcePasswordReceive());
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        // dispatchSnackbarSuccess("success");
    } catch (err) {
        console.log(err?.response?.data);
        // dispatchSnackbarError(err?.response?.data);
    }
}

export function* getProfile() {
    try {
        const response = yield call(API.getProfile);
        yield put(ACTIONS.getProfileReceive(response.data));
    } catch (err) {
        console.log(err.response.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Update Profile
export function* updateProfile({ payload }) {
    try {
        const response = yield call(API.updateProfile, payload);
        yield put(ACTIONS.updateProfileReceive(response.data));
        if (response?.status === 200) {
            localStorage.setItem('user', JSON.stringify(response?.data));
        }
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err.response.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Change Password
export function* changePassword({ payload }) {
    try {
        const response = yield call(API.changePassword, payload);
        yield put(ACTIONS.changePasswordReceive(response.data));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err.response.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* logout() {
    try {
        yield put(ACTIONS.logoutReceive());
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    } catch (err) {
        console.log(err.response.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Contact us
export function* contactUs({ payload }) {
    try {
        const response = yield call(API.contactUs, payload);
        yield put(ACTIONS.contactUsReceive(response.data));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err.response);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* authSagas() {
    yield takeLatest(TYPES.LOGIN_REQUEST, login);
    yield takeLatest(TYPES.REGISTER_REQUEST, register);
    yield takeLatest(TYPES.FORGET_PASSWORD_REQUEST, forgetPassword);
    yield takeLatest(TYPES.RESET_PASSWORD_REQUEST, resetPassword);
    yield takeLatest(TYPES.FORCE_PASSWORD_REQUEST, forcePassword);
    yield takeLatest(TYPES.GET_PROFILE_REQUEST, getProfile);
    yield takeLatest(TYPES.UPDATE_PROFILE_REQUEST, updateProfile);
    yield takeLatest(TYPES.CHANGE_PASSWORD_REQUEST, changePassword);
    yield takeLatest(TYPES.LOGOUT_REQUEST, logout);
    yield takeLatest(TYPES.CONTACT_US_REQUEST, contactUs);
}
