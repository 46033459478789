//Posts
//Get Posts
export const GET_POSTS_REQUEST = 'GET_POSTS_REQUEST';
export const GET_POSTS_RECEIVE = 'GET_POSTS_RECEIVE';
//Get Post
export const GET_POST_REQUEST = 'GET_POST_REQUEST';
export const GET_POST_RECEIVE = 'GET_POST_RECEIVE';
//Create Post
export const CREATE_POST_REQUEST = 'CREATE_POST_REQUEST';
export const CREATE_POST_RECEIVE = 'CREATE_POST_RECEIVE';
//Update Post
export const UPDATE_POST_REQUEST = 'UPDATE_POST_REQUEST';
export const UPDATE_POST_RECEIVE = 'UPDATE_POST_RECEIVE';
//Delete Post
export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST';
export const DELETE_POST_RECEIVE = 'DELETE_POST_RECEIVE';
//Get Posts List
export const GET_POSTS_LIST_REQUEST = 'GET_POSTS_LIST_REQUEST';
export const GET_POSTS_LIST_RECEIVE = 'GET_POSTS_LIST_RECEIVE';
//Toggle Post Status
export const TOGGLE_POST_STATUS_REQUEST = 'TOGGLE_POST_STATUS_REQUEST';
export const TOGGLE_POST_STATUS_RECEIVE = 'TOGGLE_POST_STATUS_RECEIVE';
